<template>
  <div class="tw-grid tw-gap-y-3 tw-gap-x-2 md:tw-gap-y-4 md:tw-gap-x-2 lg:tw-gap-y-5 lg:tw-gap-x-3 tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-6">
    <div class="tw-flex tw-items-center" v-for="item in partners">
      <NuxtImg :src="item.image" :alt="item.name" :title="item.name" class="tw-w-full tw-max-w-[160px]" />
    </div>
  </div>
  <div class=" tw-text-[#c0c0c4] tw-font-bold tw-flex tw-justify-center tw-items-center tw-text-sm md:tw-text-base lg:tw-text-xl tw-mt-5"> {{ $t('and_much_more') }}</div>
</template>
<script lang="ts" setup>
  const partners = [
    {
      name: 'Circle',
      href: 'https://www.circle.com/',
      image: `/res/logo-partners/Exchanges Wallets/Circle.png`,
    },
    {
      name: 'Kucoin',
      href: 'https://www.kucoin.com/',
      image: `/res/logo-partners/Exchanges Wallets/Kucoin.png`,
    },
    {
      name: 'Bitget',
      href: 'https://www.bitget.com/',
      image: `/res/logo-partners/Exchanges Wallets/Bitget.png`,
    },
    {
      name: 'Phemex',
      href: 'https://phemex.com/',
      image: `/res/logo-partners/Exchanges Wallets/Phemex.png`,
    },
    {
      name: 'Bitrue',
      href: 'https://bitrue.com/',
      image: `/res/logo-partners/Exchanges Wallets/Bitrue.png`,
    },
    {
      name: 'LBANK',
      href: 'https://www.lbank.info/',
      image: `/res/logo-partners/Exchanges Wallets/LBANK.png`,
    },
    {
      name: 'BitMart',
      href: 'https://www.bitmart.com/',
      image: `/res/logo-partners/Exchanges Wallets/BitMart.png`,
    },
    {
      name: 'Cobo Wallet',
      href: 'https://cobo.com/',
      image: `/res/logo-partners/Exchanges Wallets/Cobo Wallet.png`,
    },
    {
      name: 'BingX',
      href: 'https://bingx.com/en-us',
      image: `/res/logo-partners/Exchanges Wallets/BingX.png`,
    },
    {
      name: 'Xbank',
      href: 'https://xbank.plus/',
      image: `/res/logo-partners/Exchanges Wallets/Xbank.png`,
    },
    {
      name: 'XT',
      href: 'https://www.xt.com/',
      image: `/res/logo-partners/Exchanges Wallets/XT.png`,
    },
    {
      name: 'ZB',
      href: 'https://www.zb.com/',
      image: `/res/logo-partners/Exchanges Wallets/ZB.png`,
    },
    {
      name: 'Mexc',
      href: 'https://www.mexc.com/',
      image: `/res/logo-partners/Exchanges Wallets/Mexc.png`,
    },
    {
      name: 'Mexo',
      href: 'https://www.mexo.io/',
      image: `/res/logo-partners/Exchanges Wallets/Mexo.png`,
    },
    {
      name: 'TokenPocket',
      href: 'https://www.tokenpocket.pro/en',
      image: `/res/logo-partners/Exchanges Wallets/TokenPocket.png`,
    },
    {
      name: 'Tbitex',
      href: 'https://www.tbitex.com/',
      image: `/res/logo-partners/Exchanges Wallets/Tbitex.png`,
    },
    {
      name: 'Valens Exchange',
      href: 'https://valens-exchange.com/',
      image: `/res/logo-partners/Exchanges Wallets/Valens Exchange.png`,
    },
    {
      name: 'dtcpay',
      href: 'https://www.dtcpay.com/',
      image: `/res/logo-partners/Exchanges Wallets/dtcpay.png`,
    },
    {
      name: 'FomoPay',
      href: 'https://www.fomopay.com/',
      image: `/res/logo-partners/Exchanges Wallets/FomoPay.png`,
    },
    {
      name: 'Ellipal',
      href: 'https://www.ellipal.com/',
      image: `/res/logo-partners/Exchanges Wallets/Ellipal.png`,
    },
    {
      name: 'coinw',
      href: 'https://www.coinw.com/',
      image: `/res/logo-partners/Exchanges Wallets/CoinW.png`,
    },
    {
      name: 'ProBit',
      href: 'https://www.probit.com/en-us/',
      image: `/res/logo-partners/Exchanges Wallets/ProBit.png`,
    },
    {
      name: 'babylon',
      href: 'https://www.babylon.com/',
      image: `/res/logo-partners/Exchanges Wallets/babylon.png`,
    },
    {
      name: 'DoraHacks',
      href: 'https://dorahacks.com/',
      image: `/res/logo-partners/Blockchain DeFi/DoraHacks.png`,
    },
    {
      name: 'IOTEX',
      href: 'https://iotex.io/',
      image: `/res/logo-partners/Blockchain DeFi/IOTEX.png`,
    },
    {
      name: 'SWFT Coin',
      href: 'https://www.swft.pro/',
      image: `/res/logo-partners/Blockchain DeFi/SWFT Coin.png`,
    },
    {
      name: 'Dforce',
      href: 'https://dforce.network/',
      image: `/res/logo-partners/Blockchain DeFi/Dforce.png`,
    },
    {
      name: 'VeChain',
      href: 'https://www.vechain.com/',
      image: `/res/logo-partners/Blockchain DeFi/VeChain.png`,
    },
    {
      name: 'Poolin',
      href: 'https://www.poolin.com/',
      image: `/res/logo-partners/Blockchain DeFi/Poolin.png`,
    },
    {
      name: 'woox',
      href: 'https://www.woox.com/',
      image: `/res/logo-partners/Exchanges Wallets/woox.png`,
    },
    {
      name: 'KryptoGo',
      href: 'https://www.kryptogo.com/',
      image: `/res/logo-partners/Exchanges Wallets/KryptoGo.png`,
    },
    {
      name: 'CyberX',
      image: `/res/logo-partners/Exchanges Wallets/CyberX.png`,
    },
    {
      name: 'Galxe',
      image: `/res/logo-partners/Exchanges Wallets/Galxe.png`,
    },
    {
      name: 'IOSG',
      image: `/res/logo-partners/Exchanges Wallets/IOSG.png`,
    },
    {
      name: 'QBIT',
      image: `/res/logo-partners/Exchanges Wallets/QBIT.png`,
    },
    {
      name: 'StoneX',
      image: `/res/logo-partners/Exchanges Wallets/StoneX.png`,
    },
  ]
</script>
<style scoped lang="scss">
  .lightToDark {
    @include line(colors(light), colors(dark));
  }
</style>
